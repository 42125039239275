import { useEffect, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import { ethers } from 'ethers';
import { ProgressBar } from 'primereact/progressbar';
import { useWaitForTransactionReceipt, useWriteContract, useAccount, useConnect, useDisconnect } from 'wagmi'

import "primereact/resources/themes/lara-light-cyan/theme.css";
import { useDispatch, useSelector } from "react-redux";
import ifflogo from '../../Assests/Images/ifflogo.png'
import Web3 from "web3";
import {
  GetActiveRefundFun,
  GetPhaseHandler,
  Getlatestuser,
  GetlatestuserAll,
  IFFTranferHandler,
  SellIFFHandler,
  handleApproveAndTransfer,
  startLoading,
  transferIFFHandler,
} from "../../redux/auth/auth";
import {
  AdminWalletAddres,
  contractABI,
  contractAddress,
  usdtContractAbi,
  usdtContractAddress,
} from "../../common/common";
import Privacypdf from "../../Assests/pdf/Privacy_Statement.pdf";
import termsandconditionspdf from "../../Assests/pdf/Terms_and_Conditions.pdf";
import BuyPdf from "../../Assests/pdf/IFF_Buying_Guide.pdf";
import SellPdf from "../../Assests/pdf/IFF_Refunding_Guide.pdf";
import { useParams } from "react-router-dom";
import TranscationHistory from "../../pages/TranscationHistory";
import { YourApp } from "../customwallet/CustomWallet";
import InvestCard from "./InvestCard";

function InvestmentModal() {
  const { isConnected } = useAccount(); // Checks if wallet is connected
  const { connect, connectors } = useConnect(); // Connect wallet
  const { disconnect } = useDisconnect(); // Disconnect wallet
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [buyOrSell, setBuyOrSell] = useState("buy");
  const [tokenAmount, setTokenAmount] = useState("");
  const [iffAmount, setIffAmount] = useState("");
  const [activeBuySell, setActiveBuySell] = useState('')
  const { data: hash, error, writeContract } = useWriteContract()
  const [usdttoken, setUsdtToken] = useState("");
  const [usdtAmount, setUsdtAmount] = useState("");
  const [airdroped, setAirdroped] = useState("")
  const [sellUsdtPercentage, setsellUsdtPercentage] = useState('')
  const [usdtError, setUsdtError] = useState("");
  const [iffError, setIffError] = useState("");
  const [usdtData, setUsdData] = useState();
  const [errors, setErrors] = useState({});
  const [rewardPercenteage, setRewardPercentage] = useState(0)
  const [rewardState, setRewarState] = useState()
  const [targetReward, setTargetReward] = useState()
  const [targetAmount, setTargetAmount] = useState()
  const [targetAmountAdmin, setTargetAmountAdmin] = useState()
  const [buyInactiveAmount, setBuyInactiveAmount] = useState()
  //new code for handle button in mobile
  const [approvalAmount, setapprovalAmount] = useState(null)
  const [isapprovalTxHash, setisapprovalTxHash] = useState(false)

  // const modalClose = () => setShow(false);
  // const modalOpen = () => setShow(true);


  const rewardPercentage = 10;
  const [pdferrror, setPdferror] = useState({
    termsandconditions: "",
    privacypolicy: "",
  });
  const { value } = useParams();
  const metaaddress = [localStorage.getItem('meta')]
  let claimaddreess = localStorage.getItem("meta")

  const [selectCheckbox, setSelectCheckbox] = useState({
    termsandconditions: false,
    privacypolicy: false,
  });
  const [newvalue, setNewValue] = useState(0);
  const [targetState, setstateTarget] = useState(false);
  const interval = useRef(null);
  console.log(hash,"hash");
  
  useEffect(() => {
    const handleContract = async () => {
      if (hash && isapprovalTxHash) {
        
        try {
          const transferTx = await writeContract({
            address: usdtContractAddress,
            abi: usdtContractAbi,
            functionName: "transfer",
            args: [AdminWalletAddres, approvalAmount],
          });
          // modalOpen();
          dispatch(startLoading(false));
          setisapprovalTxHash(false);
        } catch (error) {
          dispatch(startLoading(false));
          setisapprovalTxHash(false);
          console.error("Error in writeContract:", error);
        }
      }
    };
    handleContract();
  }, [hash,isapprovalTxHash]);
  
  useEffect(() => {
    if (hash && !isapprovalTxHash) {
      buyFun()
      handleClose();
    }
  }, [hash])

  const { web3 } = useSelector((state) => state.slice);
  const { GetPhaselist, GETAirdropAdminHandler, isLoading, targetUser, refundStatus, getClaimdata, targetUserAll } = useSelector(
    (state) => state.slice
  );

  const handledownloadBuy = () => {

    const pdfFilePath = BuyPdf;

    // Create a link element
    const link = document.createElement("a");
    link.href = pdfFilePath;
    link.download = BuyPdf;

    // Trigger a click on the link to start the download
    link.click();
  };
  const handledownloadSell = () => {

    const pdfFilePath = SellPdf;

    // Create a link element
    const link = document.createElement("a");
    link.href = pdfFilePath;
    link.download = SellPdf;

    // Trigger a click on the link to start the download
    link.click();
  };

  useEffect(() => {
    GetPhaselist?.map((val) => {
      if (val.status == "ACTIVE") {
        return (
          setTargetAmountAdmin(val?.targetValue)
        );
      }

    })
  }, [GetPhaselist])

  useEffect(() => {
    if (GetPhaselist) {
      GetPhaselist.forEach((val) => {
        if (val.status === "ACTIVE") {
          setNewValue(targetUserAll?.ALL_USDT / val.targetValue * 100);
          setBuyInactiveAmount(val.targetValue)
        } else {
          setstateTarget(true)
        }
      });
    }
  }, [GetPhaselist, targetUser]);
  useEffect(() => {
    const totalBuyTokens = Number(targetUser.total_buy_token);
    const airdropTokens = Number(airdroped);
    let reward = 0;
    // Calculate total tokens including both bought and airdropped tokens
    const totalTokens = totalBuyTokens + airdropTokens;
    console.log(totalTokens, 'totalTokens');

    // Determine reward based on different conditions
    if (totalTokens >= 10000) {
      const previousThreshold = Math.floor(totalBuyTokens / 10000);
      const currentThreshold = Math.floor(totalTokens / 10000);

      reward = (currentThreshold - previousThreshold) * 100;
    }

    reward = Math.max(reward, 0);
    reward = Math.min(reward, 1500);
    if (getClaimdata[0]?.total_claim_bonus + reward <= 1500) {
      setTargetReward(reward);
    }
  }, [targetUser, airdroped]);


  useEffect(() => {
    dispatch(GetActiveRefundFun({ dispatch }))
  }, [])

  const usdtDataStatic = {
    id: 1,
    usdt_balance: "1",
    iff_token: "1000",
    status: "INACTIVE",
  };

  useEffect(() => {
    if (!usdtData) {
      setUsdData(usdtDataStatic)
    }
  }, [usdtData])


  const validate = () => {
    const errors = {};
    if (!usdtAmount) {
      errors.usdtAmount = "Please enter a valid USDT amount.";
    }
    const totalUSDT = Number(targetUser?.ALL_USDT || 0) + Number(usdtAmount || 0);
    if (buyOrSell === 'buy' && totalUSDT > targetAmountAdmin) {
      const remainingAmount = Math.round((targetAmountAdmin - targetUser?.ALL_USDT) * 100) / 100;
      // errors.usdtAmount = `Please enter a valid value. You can buy only ${remainingAmount} USD.`;
      errors.usdtAmount = `Due to high demand, there’s a limit of ${remainingAmount} USDT available for sale during this phase`;
    }
    if (!iffAmount) {
      errors.iffAmount = "Please enter a valid IFF amount.";
    }
    if (!selectCheckbox.termsandconditions) {
      errors.termsandconditions = "Please accept our Terms and Conditions.";
    }
    if (!selectCheckbox.privacypolicy) {
      errors.privacypolicy = "Please accept our Privacy Policy.";
    }
    setErrors(errors);
    return Object.keys(errors).length === 0; // Return true if no errors
  };

  const handleClose = () => {
    // setShow(false);
    // setBuyOrSell("");
    setUsdtError("");
    setIffError("");
    // setUsdtAmount("");
    // setIffAmount("");
    setErrors("");
  };

  const handleShow = (action) => {
    // setShow(true);
    setActiveBuySell('')
    setBuyOrSell(action);
    setTokenAmount("");
    setUsdtToken("");
    setUsdtAmount("");
    setIffAmount("");
    setErrors('')
    setAirdroped('')
    setTargetReward('')
    setTargetAmount('')
    setSelectCheckbox({
      termsandconditions: false,
      privacypolicy: false,
    })
    dispatch(GetActiveRefundFun({ dispatch }))
  };

  useEffect(() => {
    if (value) {
      handleShow('buy')
      setIffAmount(value);
      if (usdtData) {
        setUsdtAmount(
          (usdtData?.usdt_balance * Number(value)) / usdtData?.iff_token
        );
        if (value >= 250 && value < 500) {
          setAirdroped(Number(value) + (Number(value) * 1 / 100));
        } else if (value >= 500 && value < 1000) {
          setAirdroped(Number(value) + (Number(value) * 2 / 100));
        } else if (value >= 1000) {
          setAirdroped(Number(value) + (Number(value) * 5 / 100));
        }
        if (Number(value) >= 250 && Number(value) < 500) {
          setRewardPercentage(1)
        } else if (Number(value) >= 500 && Number(value) < 1000) {
          setRewardPercentage(2)
        } else if (Number(value) >= 1000) {
          setRewardPercentage(5)
        } else {
          setRewardPercentage(0)
        }
      }
    }
  }, [value, usdtData])
  useEffect(() => {
    dispatch(GetPhaseHandler({ dispatch }));
  }, []);

  useEffect(() => {
    dispatch(Getlatestuser({ dispatch }));
  }, [claimaddreess]);
  useEffect(() => {
    dispatch(GetlatestuserAll({ dispatch }));
  }, []);
  const handleInputChange = (e) => {
    let { name, value } = e.target;

    value = value.replace(/[^\d.]/g, "");

    let newIffAmount = 0;
    let newUsdt = 0;
    let newAirdroped = 0;

    if (name === "busd") {
      const numericValue = parseFloat(value) || 0;

      newIffAmount = (numericValue * Number(usdtData?.iff_token)) / Number(usdtData?.usdt_balance);

      if (newIffAmount >= 250 && newIffAmount < 500) {
        newAirdroped = newIffAmount + newIffAmount * 0.01;
      } else if (newIffAmount >= 500 && newIffAmount < 1000) {
        newAirdroped = newIffAmount + newIffAmount * 0.02;
      } else if (newIffAmount >= 1000) {
        newAirdroped = newIffAmount + newIffAmount * 0.05;
      } else {
        newAirdroped = newIffAmount;
      }

      setUsdtAmount(value);
      setTokenAmount(numericValue);
      setUsdtToken((numericValue * usdtData?.iff_token) / usdtData?.usdt_balance);

    } else if (name === "iff") {
      newIffAmount = parseFloat(value) || 0;
      newAirdroped = newIffAmount;
      newUsdt = (Number(usdtData?.usdt_balance * value) / Number(usdtData?.iff_token));

      setUsdtToken(newUsdt.toFixed(2));
      setTokenAmount(newUsdt);
      setIffAmount(newIffAmount);
      setUsdtAmount(newUsdt);
    }

    setIffAmount(newIffAmount);
    setAirdroped(newAirdroped);

    if (newIffAmount >= 250 && newIffAmount < 500) {
      setRewardPercentage(1);
    } else if (newIffAmount >= 500 && newIffAmount < 1000) {
      setRewardPercentage(2);
    } else if (newIffAmount >= 1000) {
      setRewardPercentage(5);
    } else {
      setRewardPercentage(0);
    }

    setUsdtError("");
    setIffError("");
    setErrors({});
  };
  const deductionPercentage = 12;
  useEffect(() => {
    const calculatedPercentage = iffAmount - (iffAmount * (deductionPercentage / 100));
    const formattedPercentage = parseFloat(calculatedPercentage);
    setsellUsdtPercentage(formattedPercentage.toFixed(6));
  }, [usdtAmount, deductionPercentage]);

  const localMeta = localStorage.getItem('meta');
  const buyFun = async () => {
   dispatch(
      IFFTranferHandler({
        dispatch,
        iffAmount: airdroped || iffAmount,
        contractABI,
        contractAddress,
        usdtContractAbi,
        usdtAmount,
        usdtContractAddress,
        claimaddreess
      })
    );
    // setShow(true);
    setActiveBuySell('')
    setBuyOrSell('buy');
    setTokenAmount("");
    setUsdtToken("");
    setTargetReward('')
    setTargetAmount('')
    setUsdtAmount("");
    setAirdroped('')
    setIffAmount("");
    setErrors('')
    dispatch(startLoading(false));
    setSelectCheckbox({
      termsandconditions: false,
      privacypolicy: false,
    })
  };
  // const handleSubmit = async () => {
  //   setActiveBuySell('buy');
  //   alert('test case 3o');

  //   if (!validate()) {
  //     return;
  //   }

  //   let usdtValid = true;
  //   let iffValid = true;

  //   if (!usdtAmount) {
  //     setUsdtError("Please enter a valid USDT amount.");
  //     usdtValid = false;
  //   } else {
  //     setUsdtError("");
  //   }

  //   if (!iffAmount) {
  //     setIffError("Please enter a valid IFF amount.");
  //     iffValid = false;
  //   } else {
  //     setIffError("");
  //   }

  //   if (!localMeta) {
  //     alert('Please Connect Metamask');
  //     return;
  //   }

  //   if (usdtValid && iffValid && selectCheckbox.termsandconditions && selectCheckbox.privacypolicy) {
  //     try {
  //       alert(`provider is missing,${window.ethereum}lkjhl`);
  //       dispatch(startLoading(true));
  //       const provider = new ethers.providers.Web3Provider(window.ethereum);
  //       if(!provider){
  //         alert(`provider is missing,${window}lkjhl`);
  //       }else{
  //         alert('provider')
  //       }
  //       const signer = provider.getSigner();
  //       if(!signer){
  //         alert('singer is missing');
  //       }else{
  //         alert('success2')
  //       }
  //       const usdtContract = new ethers.Contract(usdtContractAddress, usdtContractAbi, signer);
  //       if(!usdtContract){
  //         alert('usdtContract is missing');
  //       }else{
  //         alert('success1')
  //       }
  //       // Step 1: Approve the spending of USDT
  //       const Approvalamount = ethers.utils.parseUnits(usdtAmount, 6);
  //       if(!Approvalamount){
  //         alert('Approvalamount is missing');
  //       }else{
  //         alert('success3')
  //       }
  //       const approvalTx = await usdtContract.approve(AdminWalletAddres, Approvalamount);
  //       if(!approvalTx){
  //         alert('approvalTx is missing');
  //       }else{
  //         alert('success4')
  //       }

  //       await approvalTx.wait(); // Wait for the approval transaction to be mined

  //       // Step 2: Perform the transfer
  //       const transaction = await usdtContract.transfer(AdminWalletAddres, Approvalamount);
  //       if(!transaction){
  //         alert('transaction is missing');
  //       }else{
  //         alert('success transacrion')
  //       }
  //       await transaction.wait()
  //       buyFun()
  //       handleClose();
  //     } catch (error) {
  //       dispatch(startLoading(false));
  //       alert(`catch error ${error}`)
  //       console.error("Error performing transaction:", error.message);
  //     }
  //   }
  // };



  const handleSubmit = async () => {
    // Validation
    
    if (!usdtAmount) {
      setUsdtError("Please enter a valid USDT amount.");
      return;
    } else {
      setUsdtError("");
    }

    if (!iffAmount) {
      setIffError("Please enter a valid IFF amount.");
      return;
    } else {
      setIffError("");
    }

    if (!selectCheckbox.termsandconditions || !selectCheckbox.privacypolicy) {
      alert("Please accept Terms and Conditions and Privacy Policy.");
      return;
    }

    if (!isConnected) {
      alert("Please connect your wallet.");
      return;
    }
    dispatch(startLoading(true));
    try {
      const Approvalamount = ethers.utils.parseUnits(usdtAmount, 6);
      setapprovalAmount(Approvalamount)
      const approvalTx = await writeContract({
        address: usdtContractAddress,
        abi: usdtContractAbi,
        functionName: "approve",
        args: [AdminWalletAddres, Approvalamount],
      })
      setisapprovalTxHash(true)
    } catch (error) {
      console.error("Transaction failed:", error);
      alert(`Transaction catch failed: ${error} line 526`);
    }
  };


  const { isLoading: isConfirming, isSuccess: isConfirmed } =
    useWaitForTransactionReceipt({
      hash,
    })

  const hasRun = useRef({ error: false, isConfirmed: false });

  useEffect(() => {
    if (error) {
      dispatch(startLoading(false));
      toast.error(`Transaction rejected please check metamask`);
    }
  }, [error, dispatch]);


  useEffect(() => {
    hasRun.current.isConfirmed = false;
  }, [activeBuySell]);

  const handleSell = async () => {
    // Validation
    if (!iffAmount) {
      setIffError("Please enter a valid IFF amount.");
      return;
    } else {
      setIffError("");
    }
  
    if (!usdtAmount) {
      setUsdtError("Please enter a valid USDT amount.");
      return;
    } else {
      setUsdtError("");
    }
  
    if (!selectCheckbox.termsandconditions || !selectCheckbox.privacypolicy) {
      alert("Please accept Terms and Conditions and Privacy Policy.");
      return;
    }
  
    if (!isConnected) {
      alert("Please connect your wallet.");
      return;
    }
  
    dispatch(startLoading(true));
  
    try {
      const Approvalamount = ethers.utils.parseUnits(iffAmount.toString(), 18);
  
      // Approve IFF tokens for transfer
      const approvalTx = await writeContract({
        address: contractAddress,
        abi: contractABI,
        functionName: "approve",
        args: [AdminWalletAddres, Approvalamount],
      });
      // const { isLoading: isConfirming1, isSuccess: isConfirmed1 } =
      // useWaitForTransactionReceipt({
      //   hash,
      // })
      setisapprovalTxHash(true);
  
      // Perform the token transfer
      const transferTx = await writeContract({
        address: contractAddress,
        abi: contractABI,
        functionName: "transfer",
        args: [AdminWalletAddres, Approvalamount],
      });
  
      // const { isLoading: isConfirming2, isSuccess: isConfirmed2 } =
      // useWaitForTransactionReceipt({
      //   hash,
      // })
  
      // Close modal or perform success actions
      handleClose();
    } catch (error) {
      console.error("Transaction failed:", error);
      alert(`Transaction failed: ${error.message}`);
    } finally {
      dispatch(startLoading(false));
    }
  };
  


  useEffect(() => {
    GetPhaselist?.filter((value) => {
      if (value?.status === "ACTIVE") {
        setUsdData(value);
      }
      if (!usdtData) {
        setUsdData(usdtDataStatic);
      }
    });
  }, [GetPhaselist]);

  const handleSelectCheckBox = (e, checkedTo) => {
    if (checkedTo === "PrivacyPolicy") {
      setSelectCheckbox((prevState) => ({
        ...prevState,
        privacypolicy: e.target.checked,
      }));
    } else {
      setSelectCheckbox((prevState) => ({
        ...prevState,
        termsandconditions: e.target.checked,
      }));
    }
    setErrors({});
  };

  const openPDFPrivacy = () => {
    const filePath = Privacypdf;
    window.open(filePath, "_blank");
  };

  const openPDFTermsandConditions = () => {
    const filePath = termsandconditionspdf;
    window.open(filePath, "_blank");
  };

  // useEffect(() => {
  //   setsellUsdtPercentage(usdtAmount - (usdtAmount * (deductionPercentage / 100)));
  // }, [usdtAmount])

  useEffect(() => {
    if (iffAmount < 250) {
      setRewarState(250 - iffAmount)
    }
  }, [iffAmount]);


  function formatCurrency(value) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(value);
  }

  return (
    <>
      <div className="row">
        <div className="col-lg-12">
          <div className="border-bottom buy_iff_header">
            <h1>Buy <img src={ifflogo} style={{ height: '40px' }} /> IFF Token</h1>
          </div>
          {/* <div className="border-bottom123">
            <h5 className="d-flex flex-wrap justify-content-around border-bottom p-2 font_css" >
              {GetPhaselist?.map((val) => {
                if (val.status == "ACTIVE") {
                  return (
                    <>
                      <div style={{ width: "65%", display: "flex", marginTop: "18px" }}>
                        <p style={{ paddingLeft: "0px" }}>IFF Token: {val.iff_token}</p>
                        <p style={{ paddingLeft: "110px" }}>USDT Balance: {val.usdt_balance}</p>
                      </div>
                    </>
                  );
                }
              })}
            </h5>
          </div> */}
          <div className='row'>
            <div className='col-lg-8 col-sm-12 class_history_lg'>
              <TranscationHistory />
            </div>
            <div className='col-lg-4 col-sm-12'>
              <div className="box-shadow-attractive">
                <div style={{ display: 'flex', justifyContent: 'space-between', height: '50px' }}>
                  {buyOrSell == 'buy' ?
                    <div style={{ display: 'flex' }}>
                      {/* <label className={usdtData?.status == 'ACTIVE' ? "pulsing-dot" : "pulsing-dot_Inactive"}></label> */}
                      <label
                        className={
                          usdtData?.status === 'ACTIVE' ||
                            targetUser?.ALL_USDT < targetAmountAdmin
                            ? "pulsing-dot"
                            : "pulsing-dot_Inactive"
                        }
                      ></label>
                      <label className={usdtData?.status == 'ACTIVE' || targetUser?.ALL_USDT < targetAmountAdmin
                        ? "pulsing-doted" : "pulsing-doted-dot"}
                        style={{
                          fontSize: '15px', fontWeight: '400', color: 'black',
                          marginTop: targetUser?.target_status != "active" ? "12px" : "12px",
                          minWidth: targetUser?.target_status != "active" ? "90px" : "90px",
                          minWidth: '150px',
                          marginLeft: usdtData?.status == "ACTIVE" ? "" : claimaddreess != null ? "10px" : "4px",
                          marginLeft: targetUser?.total_usdt >= targetAmountAdmin ? "1px" : "4px",
                        }}>
                        {usdtData?.status == 'ACTIVE' ||
                          targetUser?.ALL_USDT < targetAmountAdmin ? `Phase ${usdtData?.index + 1} Live` : 'No Phase Active'}
                      </label>
                    </div> :
                    <div style={{ justifyContent: 'start', display: 'flex', width: "100%" }}>
                      <p className={refundStatus[0]?.status == "1" ? "pulsing-dot" : "pulsing-dot_Inactive"} style={buyOrSell === "refund" ? { width: "50%" } : {}}></p>
                      <p className={refundStatus[0]?.status == "1" ? "pulsing-doted" : "pulsing-doted-dot"}
                        style={{
                          fontSize: '15px', fontWeight: '400',
                          minWidth: refundStatus[0]?.status != "1" ? "192px" : "160px",
                          marginTop: refundStatus[0]?.status != "1" ? "10px" : "10px", color: 'black'
                        }}>
                        {refundStatus[0]?.status == "1" ? "Refunds are available" : "Refund option unavailable"}
                        {/* This Sale Ended. Stay Tuned! */}
                      </p>
                    </div>}
                  <div style={{ justifyContent: 'end', display: 'flex', width: '100%', marginTop: "10px" }}>
                    <p style={{ marginRight: "-10px", fontSize: '15px', display: 'flex', color: 'black' }}>RAISED: {targetUserAll?.ALL_USDT ? formatCurrency(targetUserAll?.ALL_USDT) : '$0'}<span style={{ fontSize: '20px' }}></span></p>
                  </div>
                </div>
                <div>
                  <div className="card" style={{ height: '10px', position: 'relative' }}>
                    <ProgressBar value={newvalue && newvalue}></ProgressBar>
                  </div>
                </div>
                {/* {GetPhaselist?.map((val) => {
                  if (val.status == "ACTIVE") {
                    return (
                      <>
                        <div style={{ justifyContent: 'end', display: 'flex' }}>
                          <p style={{ fontSize: '15px', marginTop: "10px", marginBottom: "10px", color: 'black' }}>TARGET: {val?.targetValue ? formatCurrency(val?.targetValue) : '$0'}</p>
                        </div>
                      </>
                    );
                  }

                })} */}
                <div style={{ justifyContent: 'end', display: 'flex' }}>
                  <p style={{ fontSize: '15px', marginTop: "10px", marginBottom: "10px", color: 'black' }}>TARGET: {targetAmountAdmin ? formatCurrency(targetAmountAdmin) : '$0'}</p>
                </div>
                <div className="row" style={{ marginBottom: "20px", marginTop: !GetPhaselist ? "10px" : "0" }}>
                  <div className="col-12" style={{ backgroundColor: "#61a1fe", padding: "5px", display: 'flex', alignItems: 'center', justifyContent: 'center', maxWidth: '310px', margin: '0 auto' }}>
                    <div style={{ flex: 1, color: "white", textAlign: "center", fontSize: 12 }}>
                      Current Price
                      <br />
                      ${usdtData?.usdt_balance / usdtData?.iff_token}
                    </div>
                    <div style={{ borderLeft: '1px solid white', height: '100%', margin: '0 10px' }}></div>
                    <div style={{ flex: 1, color: "white", textAlign: "center", fontSize: 12 }}>
                      Final Phase Price
                      <br />
                      $3.20
                      {/* {Math.round(usdtData?.usdt_balance / usdtData?.iff_token)} */}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <Button variant="primary" onClick={() => handleShow("buy")} style={{ width: '100%' }} disabled={buyOrSell == 'buy'}>
                      Buy
                    </Button></div>
                  <div className="col-6">
                    <Button
                      variant="primary"
                      onClick={() => handleShow("sell")}
                      style={{ width: '100%' }}
                      disabled={buyOrSell == 'sell'}
                    >
                      Refund
                    </Button>
                  </div>
                </div>

                <div style={{ marginTop: '10px' }}>
                  {buyOrSell === "buy" ? (
                    <div>
                      <div>
                        <label>Pay with USDT</label>
                        <div>
                          <input
                            type="text"
                            placeholder="0"
                            name="busd"
                            className="investment_modal_css"
                            onChange={handleInputChange}
                            value={usdtAmount}  // Directly use `usdtAmount` state without converting it to a number
                          />
                          <p className="text-danger" style={{ fontSize: "12px" }}>{errors.usdtAmount}</p>
                        </div>
                      </div>
                      <div className="my-3">
                        <label>Buy IFF</label>
                        <div>
                          <input
                            type="text"
                            placeholder="0"
                            className="investment_modal_css"
                            name="iff"
                            onChange={handleInputChange}
                            // value={airdroped}
                            value={isNaN(Number(airdroped)) ? 0 : Number(airdroped)}
                          />
                          <p className="text-danger">
                            {errors.iffAmount ? errors.iffAmount : rewardPercenteage > 0 ? (
                              <p style={{ color: 'gray' }}>Activated Bonus: Airdrop Reward +{rewardPercenteage}%</p>
                            ) : (
                              <p style={{ color: 'gray', fontSize: '12px' }}>Activate Bonus: Add {rewardState && rewardState} IFF more to get Airdrop Reward.</p>
                            )}
                          </p>
                          {targetUser?.bonus == 1500 && airdroped ? <p style={{ backgroundColor: "green", color: "white", width: "340px", display: "flex", borderRadius: '5px', height: '30px', textAlign: 'center', alignItems: 'center', paddingLeft: '10px' }}>Maximum VIP Bonus Reward Reached</p> : targetReward && targetReward ? <p style={{ backgroundColor: "green", color: "white", width: "340px", display: "flex", borderRadius: '5px', height: '30px', textAlign: 'center', alignItems: 'center', paddingLeft: '10px' }}>Activated VIP Bonus Reward: {targetReward && targetReward} IFF </p> : ''}
                        </div>
                      </div>
                      <div>
                        <div>
                          <input
                            type="checkbox"
                            name="checkbox1"
                            checked={selectCheckbox?.termsandconditions}
                            onChange={(e) =>
                              handleSelectCheckBox(e, "TermAndCondition")
                            }
                            value={buyOrSell === "buy" ? iffAmount : usdttoken}
                          />
                          <a
                            onClick={openPDFTermsandConditions}
                            style={{
                              marginLeft: "10px",
                              color: "#4c4cd1",
                              cursor: "pointer",
                              fontSize: '12px'
                            }}
                          >
                            By ticking this box, you agree to our Terms and Conditions.
                          </a>
                          <p className="text-danger" style={{ fontSize: '10px' }}>{errors.termsandconditions}</p>
                        </div>
                      </div>
                      <div>
                        <div>
                          <input
                            type="checkbox"
                            name="checkbox2"
                            checked={selectCheckbox?.privacypolicy}
                            onChange={(e) =>
                              handleSelectCheckBox(e, "PrivacyPolicy")
                            }
                          />
                          <a
                            onClick={openPDFPrivacy}
                            style={{
                              marginLeft: "10px",
                              color: "#4c4cd1",
                              cursor: "pointer",
                              fontSize: '12px'
                            }}
                          >
                            By ticking this box, you agree to our Privacy Policy.
                          </a>
                          <p className="text-danger" style={{ fontSize: '10px' }}>{errors.privacypolicy}</p>
                        </div>
                        {metaaddress[0] ? <Button
                          className="w-100"
                          variant="primary"
                          onClick={handleSubmit}
                          disabled={usdtData?.status == 'INACTIVE' || targetUser?.ALL_USDT >= buyInactiveAmount}
                        >
                          <p className="my-2">Buy IFF</p>
                        </Button> :
                          //  <Button
                          //   className="w-100"
                          //   variant="primary"
                          // >
                          <YourApp />
                          // </Button>
                        }
                      </div>

                    </div>
                  ) : (
                    <div>
                      <div className="my-2">
                        <label>IFF</label>
                        <div>
                          <input
                            type="text"
                            placeholder="0"
                            className="investment_modal_css"
                            name="iff"
                            onChange={handleInputChange}
                            value={iffAmount}
                          />
                          <p className="text-danger">{errors.iffAmount}</p>
                        </div>
                      </div>
                      <div>
                        <label>Refund USDT</label>
                        <div>
                          <input
                            type="text"
                            placeholder="0"
                            name="busd"
                            className="investment_modal_css"
                            onChange={handleInputChange}
                            value={isNaN(Number(sellUsdtPercentage)) ? 0 : Number(sellUsdtPercentage)}
                          />
                          <p className="text-danger">
                            {errors.usdtAmount ? errors.usdtAmount : usdtAmount ? (
                              <p style={{ color: 'gray' }}>A transaction fee of 12% has been applied.</p>
                            ) : (
                              <p style={{ color: 'gray', fontSize: '12px' }}>A transaction fee of 12% will be applied.</p>
                            )}
                          </p>
                        </div>
                      </div>
                      <div>
                        <div>
                          <input
                            type="checkbox"
                            name="checkbox1"
                            checked={selectCheckbox?.termsandconditions}
                            onChange={(e) =>
                              handleSelectCheckBox(e, "TermAndCondition")
                            }
                            value={buyOrSell === "buy" ? iffAmount : usdttoken}
                          />
                          <a
                            onClick={openPDFTermsandConditions}
                            style={{
                              marginLeft: "10px",
                              color: "#4c4cd1",
                              cursor: "pointer",
                              fontSize: '12px'
                            }}
                          >
                            By ticking this box, you agree to our Terms and Conditions.
                          </a>
                          <p className="text-danger" style={{ fontSize: '10px' }}>{errors.termsandconditions}</p>
                        </div>
                      </div>
                      <div>
                        <div>
                          <input
                            type="checkbox"
                            name="checkbox2"
                            checked={selectCheckbox?.privacypolicy}
                            onChange={(e) =>
                              handleSelectCheckBox(e, "PrivacyPolicy")
                            }
                          />
                          <a
                            onClick={openPDFPrivacy}
                            style={{
                              marginLeft: "10px",
                              color: "#4c4cd1",
                              cursor: "pointer",
                              fontSize: '12px'
                            }}
                          >
                            By ticking this box, you agree to our Privacy Policy.
                          </a>
                          <p className="text-danger" style={{ fontSize: '10px' }}>{errors.privacypolicy}</p>
                        </div>
                      </div>
                      {metaaddress[0] ? <Button
                        className="w-100"
                        variant="primary"
                        onClick={handleSell}
                        disabled={refundStatus[0]?.status == '0'}
                      >
                        <p className="my-2">Refund USDT</p>
                      </Button> :
                        //  <Button
                        //   className="w-100"
                        //   variant="primary"
                        // >
                        <YourApp />
                        // </Button>
                      }
                    </div>
                  )}
                </div>
                <div style={{ cursor: 'pointer', textAlign: 'center' }}>
                  {buyOrSell === "buy" ? <a onClick={handledownloadBuy}>   HOW TO BUY?</a> : <a onClick={handledownloadSell}>   HOW TO GET A REFUND?</a>}
                </div>
              </div>
            </div>
            <div className='col-lg-8 col-sm-12  class_history_sm' style={{ marginTop: '30px' }}>
              <TranscationHistory />
            </div>
          </div>
          <div className="d-flex my-2">
            <div>
            </div>
          </div>
        </div >
      </div >
      {/* <InvestCard targetReward={targetReward} /> */}
      <>
      {/* <Button variant="primary" onClick={modalOpen}>
        Launch demo modal
      </Button>

      <Modal show={show} onHide={modalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmed Transaction</Modal.Title>
        </Modal.Header>
        <Modal.Body><p>Woohoo, You have confirmed the transction</p>
        </Modal.Body>
       
      </Modal> */}
    </>
    </>
  );
}

export default InvestmentModal;
